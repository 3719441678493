import React,  { useRef, useState, forwardRef, useImperativeHandle } from "react";
import { useCookies } from 'react-cookie';
import * as Mui  from '@mui/material';
import * as MuiIcon from '@mui/icons-material';
import * as MuiLab from '@mui/lab';
import * as MuiJoy from '@mui/joy';

import { MuiFileInput } from 'mui-file-input';
import { useForm, SubmitHandler } from "react-hook-form"
import axios from 'axios';
import MsgBox from '../lib/MsgBox';


const DrawerAddProject= forwardRef((props,ref) => {
    const [cookies] = useCookies(['bas_']);
    const formRef = useRef();
    const [taxo,setTaxo] = useState([]);
    const [loading,setLoading] = React.useState(false)
    const [error,setError] = React.useState([])
    const [isMsgBox, setisMsgBox] = React.useState({
      open: false,
      status: 'ok',
      title:'',
      msg: '',
      action:''
    });
    const { register, getValues, setValue, handleSubmit,reset } = useForm()
    const onSubmit = (data) => {
      loadingOn()
        
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization",cookies.token);

      const formdata = new FormData();

      formdata.append("po_number", getValues("po_number"))
      formdata.append("project_type_id", getValues("project_type_id"))
      
      //Setup Product
        var products = '[';
        for (var i = 0; i < getValues("qty").length ; i++ ) {
          products +=  `{\"description\":\"${getValues("description")[i]}\",\"qty\":${getValues("qty")[i]}}`
          if(i < (getValues("qty").length-1) ){ products += `,` }
        }
        products += ']'
        
        formdata.append("products", products)
      
          /*for (var pair of formdata.entries()) {
              console.log(pair); 
          }*/

          //Setup Photo
          for (var i = 0; i < filePhoto.length ; i++ ) {
            formdata.append("files[]", filePhoto[i], filePhoto[i].name)
          }
          
          var obj
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
          };


          fetch(props.config.hostApi + 'project/create', requestOptions)
              .then((response) => response.json())
              .then((result) => {
                obj = result
                if(result.success){
                  refreshParentProjects()
                  loadingOff()
                  reset()
                }else{
                  setError(result.errors)
                  loadingOff()
                  setisMsgBox({
                    open: true,
                    status: 'error',
                    title:'Save Project',
                    msg: result.message,
                  })
                }
              })
              .then(()=>{
                console.log('disini')
                console.log(obj)
                loadingOff()
              })
          
         
    }
    const [state, setState] = React.useState({
      isForm:false,
    });

    useImperativeHandle(ref, () => ({
      openForm: (taxo) => {
        setState({ 
          isForm: true,  
         })
      }
   }));

    const loadingOn = () =>{
      setLoading(true)
    }
    const loadingOff = () =>{
      setLoading(false)
    }
    const refreshParentProjects = () => {
      props.refreshProjectSave()
      setState({
        isForm:false,
      })
    }
    
    const [fileObj,setfileObj] = useState([]);
    const [fileArray,setfileArray] = useState([]);
    const [filePhoto,setfilePhoto] = useState(null);

    const uploadMultipleFiles = (e)=>{
      setfileObj([])
      console.log(e.target.files)
      /*fileObj.push(e.target.files)
      for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]))
      }*/
      //setfile(fileArray)
    }
    const uploadMultipleFiles1 = (e)=>{
      setfileObj([])
      fileObj.push(e)
      for (let i = 0; i < fileObj[0].length; i++) {
          fileArray.push(URL.createObjectURL(fileObj[0][i]))
      }
    }

    const deletePhoto = (index) => {
      setfilePhoto(null);
      setfileArray([])
    }

    const getTaxo = () => {
      const header ={
          'Accept': 'application/json',
          'Authorization': cookies.token
        }
        axios.post( props.config.hostApi + 'taxo',
         {
          object : ["project-type"]
         },
         { headers: header })
          .then(res => {
              setTaxo(res.data.data['project-type'])
            })
          .catch((error) => {
            //console.log(error)
          })
    }
  React.useEffect(() => {
    getTaxo()
  }, []);  

  return (
    <>
      <MsgBox config={isMsgBox} />
      <Mui.Drawer open={state.isForm} PaperProps={{sx: { width: "90%", maxWidth: "800px" },}}>
          <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Mui.Toolbar disableGutters style={{padding:'0px 20px', backgroundColor:"#eeeeee"}}> 
                  <Mui.Typography
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: "bold" }}
                    color="text.secondary"
                  >
                    Add New Project
                  </Mui.Typography>
                  <Mui.Box sx={{ flexGrow: 1 }}>&nbsp;</Mui.Box>
                  <MuiJoy.Button
                    variant="solid"
                    loadingPosition="start"
                    onClick={()=> setState({isForm:false})}    
                      sx={{ margin: '0px 3px' }}
                      startDecorator={<MuiIcon.Replay/>}
                    > Back 
                  </MuiJoy.Button>
                  <MuiJoy.Button
                    type="submit"
                    loading={loading}
                    loadingPosition="start"
                    variant="solid"  
                    sx={{ margin: '0px 3px'}}
                    startDecorator={<MuiIcon.Save />} 
                    > Save
                  </MuiJoy.Button>
            </Mui.Toolbar>
            <Mui.DialogContent>
                    <Mui.Grid container>
                        <Mui.Grid item xs={12} className="innerForm" textAlign={"right"}>
                            <span>* Require Field</span>
                        </Mui.Grid>
                          <Mui.Grid item xs={4} className="innerForm">
                              <Mui.TextField label="*PO Number" size="small" variant="outlined" className="inputField" {...register("po_number")} />
                              {error.filter(error => error.path === 'po_number').length > 0 && <p className="error">{error.filter(error => error.path === 'po_number')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={4} className="innerForm">
                              <Mui.FormControl sx={{ width: '100%' }}>
                                  <Mui.TextField
                                    select
                                    size="small"
                                    label="*Project Type"
                                    {...register("project_type_id")} 
                                >
                                    {taxo.map((propectType,key) => (
                                    <Mui.MenuItem key={key} value={propectType.id}>{propectType.value}</Mui.MenuItem>
                                    ))}
                                    </Mui.TextField>
                                </Mui.FormControl>
                                {error.filter(error => error.path === 'project_type_id').length > 0 && <p className="error">{error.filter(error => error.path === 'project_type_id')[0].msg}</p>}
                      
                          </Mui.Grid>
                          <Mui.Grid item xs={4} className="innerForm">
                              <MuiFileInput
                                  placeholder="Product Items" 
                                  value={filePhoto}
                                  sx={{border:'1px solid #FF9B00', borderRadius:'5px'}}
                                  variant="outlined"
                                  size="small"
                                  InputProps={{
                                    startAdornment: <MuiIcon.AttachFile />
                                  }}
                                  clearIconButtonProps={{
                                    children: <MuiIcon.Close fontSize="12px" />,
                                    onClick:()=>{
                                      deletePhoto()
                                    }
                                  }}
                                  onChange={(newfile) => {
                                    setfilePhoto(newfile);
                                    uploadMultipleFiles1(newfile)
                                  }} 
                                  multiple
                              />
                          </Mui.Grid>
                          <Mui.Grid item xs={12} className="innerForm">
                                  <Mui.ImageList sx={{ width: '100%', height: 600 }}>
                                    
                                  {fileArray.map((url,key) => (
                                      <Mui.ImageListItem key={url}>
                                        <img
                                            src={url}
                                          />
                                          <Mui.TextField
                                              multiline
                                              size="small"
                                              rows={2}
                                              label="Description"
                                              {...register(`description.${key}`)}
                                          />
                                          <Mui.TextField label="Qty" 
                                              {...register(`qty.${key}`)} 
                                              sx={{margin:'5px 0 25px 0'}} size="small" variant="outlined" className="inputField" />
                                      </Mui.ImageListItem>
                                    ))}
                                  </Mui.ImageList>
                          </Mui.Grid>
                    </Mui.Grid>
                
            </Mui.DialogContent>
          </form>
          
      </Mui.Drawer>  
    </>
  );
});

export default DrawerAddProject;
